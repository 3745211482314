/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

 import React from "react"
 import PropTypes from "prop-types"
 import { useStaticQuery, graphql } from "gatsby"
 
 import Header from "../Header/Header"
 import Footer from "../Footer/Footer"
 import CTA from "../CTA/CTA"
 
 const Layout = ({ children }) => {
 
     const data = useStaticQuery(graphql`
     query SiteTitleQuery {
       site {
         siteMetadata {
           title
         }
       }
     }
   `)
 
     return (
      <div>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div className="space-y-8">
          <main >
              {children}
          </main>
          <CTA />
          <Footer />
        </div>
      </div>
     )
 }
 
 Layout.propTypes = {
     children: PropTypes.node.isRequired,
 }
 
 export default Layout